import React from 'react';

interface Props {
	label?: any,
	inline?: string | boolean,
	name?: string,
	checked?: boolean,
	onChange?: any,
	disabled?: boolean,
	value?: number | string
}

const Checkbox = (props: Props) => {
	const isString = props.label && typeof props.label === 'string';
	const random = Math.random();
	return (
		<div className={ `form-check form-group ${ props.inline ? 'form-check-inline' : '' }` }>
		  <input disabled={ props.disabled } className="form-check-input" type="checkbox" { ...props } id={ `checkbox-${ (isString ? props.label.replace(' ','') : 'checkbox') + props.value?.toString() }` + random } />
		  { props.label && <label className="form-check-label" htmlFor={ `checkbox-${ (isString ? props.label.replace(' ','') : 'checkbox') + props.value?.toString() }` + random }> 
		    { props.label }
		  </label> }
		</div>
	)
}

export default Checkbox;