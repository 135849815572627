import React from 'react';
import { ClientService } from 'services';
import { Button, Input, Modal, Table, Tooltip } from 'components';
import { checkPermission, Permissions, Constants, Globals, Socket, SocketEvents} from 'utils';
import { RootState } from 'reducers';
import { connect } from 'react-redux';
import { withRouter , RouteComponentProps, useHistory} from 'react-router-dom';
import { EditIcon, HouseIcon, TrashIcon, ParentFolder, CheckIcon, CloseIcon, ViewFileIcon } from 'assets/icons';
import Swal from 'sweetalert2';
import colors from './../../../utils/colors';
import { PropertyService } from 'services';
import CreateSolvency from 'screens/clients/urban-cleanliness/create-solvency';

interface LocationProps {
	page: number | undefined;
	form: any
}
  
interface Props{
	client: any,
	history: any,
}

const backgroundDepth = ['transparent', '#e3e3e3', '#d4d4d4', '#c8c8c8', '#a5a5a5', '#9b9b9b']

const { ACTIVE, INACTIVE } = Constants.USERS_STATUS;

class ViewClient extends React.Component<Props> {

	state: any = {
		client: null,
		header: [
			'Código',
			'Propiedades',
			'Datos',
			'Uso',
			'Actividad Económica',
			'Nro de casas o apartamentos',
			'Acción'
		],
		form: {
			parent_id: '',
		},
		propertyVisible: {},
		visible: false,
		searchProperties: '',
		visible_solvency: false,
		user_solvency: null,
	}

	componentDidMount() {
		this.load();
	}

	load = async () => {
		const res: any = await ClientService.admin.getUser({
			id: this.props.client?.id
		});
		this.setState({
			client: res.user
		});
	}

	edit = (e: any, url: string) => {
		e.preventDefault();
		this.props.history.push(url);
	}

	remove = async (e: any, item: any) => {
		e.preventDefault();

		try {
			const result = await Swal.fire({
				title: "¿Esta seguro de eliminar?",
				text: "Esta acción no se podrá revertir",
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: colors.swal,
			});

			if (result.value) {
				const res: any = await PropertyService.delete({ property_id: item.id });

				Swal.fire({
					title: "¡Éxito!",
					text: "Se eliminó el registro exitosamente",
					type: "success",
					confirmButtonColor: colors.swal,
				});
			}
		} catch (error) {
			console.log(error);
			Swal.fire({
				title: "Error",
				text: "No es posible eliminar",
				type: "error",
				confirmButtonColor: colors.swal,
			});
		} finally {
			this.load();
		}
	}

	submitAddParent = async (e: any) => {
		e.preventDefault();

		if (!this.state.propertyVisible) return;
		if (!this.state.form.parent_id.trim()) {
			Swal.fire({
				title: "Error",
				text: "El código es requerido",
				type: "error",
				confirmButtonColor: colors.swal,
			});
			return;
		}

		const data = this.state.propertyVisible
		const findParentId = this.state.form.parent_id

		if (data.urbaser_code === findParentId) {
			Swal.fire({
				title: "Error",
				text: "El código debe ser diferente al de la propiedad",
				type: "error",
				confirmButtonColor: colors.swal,
			});
			return;
		}

		try {
			const res: any = await PropertyService.addParent(data.id, { findParentId });

			Swal.fire({
				title: "¡Éxito!",
				text: "Se padre agregado exitosamente",
				type: "success",
				confirmButtonColor: colors.swal,
			});
		} catch (error) {
			console.log(error);
			Swal.fire({
				title: "Error",
				text: "No se pudo agregar el padre",
				type: "error",
				confirmButtonColor: colors.swal,
			});
		} finally {
			this.load();
			this.onClose();
		}
	}

  addParent = (e: any, item: any) => {
		e.preventDefault();

    this.setState({
      visible: true,
			propertyVisible: item,
    });
  }

  onClose = () => {
    this.setState({
      visible: false,
			propertyVisible: null,
    });
  }

	onFilter = (property: any) => {
		const { searchProperties } = this.state;

		if (!searchProperties) return true;

		const search = searchProperties?.toLowerCase();

		if (property.secondary_properties && property.secondary_properties?.length !== 0) {
			return property.secondary_properties.some((prop: any) => {
				return prop.urbaser_code?.toLowerCase().includes(search) ||
					prop.house_number?.toLowerCase().includes(search) ||
					prop.use?.name?.toLowerCase().includes(search) ||
					prop.economic_activity?.name?.toLowerCase().includes(search) ||
					prop.quantity_houses?.toString().includes(search);
			})
		}

		return property.urbaser_code?.toLowerCase().includes(search) ||
			property.house_number?.toLowerCase().includes(search) ||
			property.use?.name?.toLowerCase().includes(search) ||
			property.economic_activity?.name?.toLowerCase().includes(search) ||
			property.quantity_houses?.toString().includes(search);
	}

	changeStatus = (e: any, i: any) => {
		e.preventDefault();
		Globals.confirm(`¿Desea ${ i.user.status == ACTIVE ? 'desactivar' : 'activar' } este usuario?`,async () => {
			await ClientService.admin.status({
				id: i.user.id,
				status: i.user.status == ACTIVE ? INACTIVE : ACTIVE
			});
			Globals.showSuccess("Se ha modificado correctamente el usuario");
			Socket.emit(SocketEvents.USERS.CHANGE_STATUS,{
				id: i.user.id,
			});
			this.load();
		});
	}

	requestSolvency = async (e: any, item: any) => {
		e.preventDefault();
		try {
			const data: any = await ClientService.admin.getProperties({ user_id: item.user.id, withoutLoading: true });
		
			const properties = data.properties?.map((p: any) => ({
				value: p.id,
				label: p.urbaser_code,
				...p,
			}));
			this.setState({
				visible_solvency: true,
				user_solvency: { ...item.user, properties },
			});
		} catch (error) {
		  	console.log(error);
		}
	}

	onCloseSolvency = () => {
		this.setState({
			visible_solvency: false,
			user_solvency: null,
		});
	}

	getRow = (property: any, index: number, depth = 1) => {
		const color = backgroundDepth[depth - 1]

		return <React.Fragment>
			<tr key={ index }>
				<td style={{ background: color, paddingLeft: `${depth * 12}px` }}>{ property.urbaser_code }</td>
				<td style={{ background: color }}> { property.childs > 0 ? property.childs : '-' }</td>
				<td style={{ background: color }}>NRO: {property.house_number ?? ''}</td>
				<td style={{ background: color }}>{ property.use?.name || '-' }</td>
				<td style={{ background: color }}>{ property.economic_activity?.name || '-' }</td>
				<td style={{ background: color }}>{ property.quantity_houses }</td>
				<td style={{ background: color }}>
					{
						depth === 1 && checkPermission(Permissions.CLIENTS.CREATE) && (
							<React.Fragment>
								<Tooltip title="Editar">
									<a className="link-icon" href="#" onClick={ (e: any) => this.edit(e,`/admin/users/clients/edit/${ property.user_id }/${ property.id }`) }>
										<img src={ EditIcon } />
									</a>
								</Tooltip>
								<Tooltip title="Agregar propiedad">
									<a className="link-icon" href="#" onClick={ (e: any) => this.edit(e,`/admin/users/clients/${ property.user_id }/create-property/${ property.id }`) }>
										<img src={ HouseIcon } />
									</a>
								</Tooltip>
								{ checkPermission(Permissions.CLIENTS.DELETE) && (<>
									<Tooltip title="Eliminar">
										<a className="link-icon" href="#" onClick={ (e: any) => this.remove(e, property) }>
											<img src={ TrashIcon } />
										</a>
									</Tooltip>
									<Tooltip title={ property.user?.status == ACTIVE ? 'Desactivar' : 'Activar' }>
										<a className="link-icon" href="#" onClick={ (e: any) => this.changeStatus(e, property) }>
										<img src={ property.user?.status == ACTIVE ? CloseIcon : CheckIcon } />
										</a>
									</Tooltip>
								</>
								)}
								<Tooltip title="Agregar padre">
									<a className="link-icon" href="#" onClick={ (e: any) => this.addParent(e, property) }>
										<img src={ ParentFolder } />
									</a>
								</Tooltip>
								<Tooltip title="Solicitar Solvencia">
									<a className="link-icon" href="#" onClick={ (e: any) => this.requestSolvency(e, property) }>
										<img src={ ViewFileIcon } />
									</a>
								</Tooltip>
							</React.Fragment>
						)
					}
					{
						depth > 1 && checkPermission(Permissions.CLIENTS.CREATE) && (
							<React.Fragment>
								<Tooltip title="Editar">
									<a className="link-icon" href="#" onClick={ (e: any) => this.edit(e,`/admin/users/clients/edit/${ property.user_id }/${ property.id }`) }>
										<img src={ EditIcon } />
									</a>
								</Tooltip>
								{ checkPermission(Permissions.CLIENTS.DELETE) && (<>
									<Tooltip title="Eliminar">
										<a className="link-icon" href="#" onClick={ (e: any) => this.remove(e, property) }>
											<img src={ TrashIcon } />
										</a>
									</Tooltip>
									<Tooltip title={ property.user?.status == ACTIVE ? 'Desactivar' : 'Activar' }>
										<a className="link-icon" href="#" onClick={ (e: any) => this.changeStatus(e, property) }>
										<img src={ property.user?.status == ACTIVE ? CloseIcon : CheckIcon } />
										</a>
									</Tooltip>
								</>
								)}
								<Tooltip title="Cambiar padre">
									<a className="link-icon" href="#" onClick={ (e: any) => this.addParent(e, property) }>
										<img src={ ParentFolder } />
									</a>
								</Tooltip>
								<Tooltip title="Solicitar Solvencia">
									<a className="link-icon" href="#" onClick={ (e: any) => this.requestSolvency(e, property) }>
										<img src={ ViewFileIcon } />
									</a>
								</Tooltip>
							</React.Fragment>
						)
					}
				</td>
			</tr>
			{ property.secondary_properties?.filter(this.onFilter).map((prop: any, i: number) => {
				return this.getRow(prop, i, depth + 1)
			}) }
		</React.Fragment>
	}

	render() {
		const visible: boolean = this.state.visible;
		const client: any = this.state.client;

		return (
			<div id="admin-view-client">
				<Modal
          className="modal-view-account-status"
					style={{ background: 'rgba(0,0,0,0.5)' }}
          visible={visible}
          onClose={this.onClose}
          title="Agregar padre"
        >
          <form onSubmit={ this.submitAddParent }>
						<div className="row">
							<div className="col-md-12">
								<Input
									color="white"
									value={ this.state.form.parent_id }
									name="parent_id"
									precision="0"
									placeholder="Código del padre"
									onChange={ (e: any) => {
										this.setState({
											form: {
												...this.state.form,
												parent_id: e.target.value,
											},
										})
									} } />
							</div>
							<div className="col-md-12">
								<Button type="submit">
									Guardar
								</Button>
							</div>
						</div>
					</form>
        </Modal>
		<Modal
			className="modal-create-solvency"
			visible={ this.state.visible_solvency }
			onClose={ this.onCloseSolvency }
			title="Generar Solvencia de Aseo Urbano"
			backdropClassName="modal-backdrop-z-1050"
		>
			<CreateSolvency
				user={this.state.user_solvency}
				properties={this.state.user_solvency?.properties}
				onClose={this.onCloseSolvency}
			/>
		</Modal>
				{
					client && (
						<React.Fragment>
							<h3>
								<span>Datos del Contribuyente</span>
								<Tooltip title="Editar">
									<a className="link-icon ml-2" href="#" onClick={ (e: any) => this.edit(e,`/admin/users/clients/edit/${ client.id }`) }>
										<img src={ EditIcon } />
									</a>
								</Tooltip>
							</h3>
							<div className="container-data">
								<div className="item-data">
									<p><strong>Nombre:</strong> { client.name }</p>
									<p><strong>Apellido:</strong> { client.lastname }</p>
									<p><strong>Usuario:</strong> { client.username }</p>
									<p><strong>CI/RIF:</strong> { client.full_document }</p>
									<p><strong>Teléfono:</strong> { client.full_phone }</p>
									{ client.phones.length > 0 && <p>Teléfonos adicionales: { client.phones.map((i: any) => i.phone).join(', ') }</p> }
								</div>
								<div className="item-data">
									<p><strong>Dirección Fiscal:</strong> { client.fiscal_address }</p>
									<p><strong>Razón Social:</strong> { client.business_name }</p>
									<p><strong>Correo:</strong> { client.email }</p>
									<p><strong>Agente de Retención:</strong> { client.retention == Constants.RETENTION.YES ? 'Si' : 'No' }</p>
									{ client.emails.length > 0 && <p><strong>Correos adicionales:</strong> { client.emails.map((i: any) => i.email).join(', ') }</p> }
								</div>
							</div>
							{
								client.properties.length > 0 && (
									<React.Fragment>
										<hr />
										<h3 className="d-flex justify-content-between align-items-center">
											<span>Propiedades del Contribuyente</span>
											<Button className="btn-small" onClick={ (e: any) => this.edit(e,`/admin/users/clients/${ client.id }/create-property`) }>Agregar propiedad</Button>
										</h3>
										<div className="w-25 mt-3">
											<Input
                        color="white"
                        value={this.state.searchProperties}
                        name="searchProperties"
                        placeholder="Buscar..."
                        required={true}
                        onChange={(e: any) => {
													this.setState({
														searchProperties: e.target.value
													})
												}}
                      />
										</div>
										<div className="item-property">
											<Table title="" data={ 1 } header={ this.state.header }>
												{ client.properties.filter(this.onFilter).map((property: any, index: number) => this.getRow(property, index, 1)) }
											</Table>
										</div>
									</React.Fragment>
								)
							}
						</React.Fragment>
					)
				}
			</div>
		)
	}
}


export default ViewClient;