import React from 'react'
import { RootState } from 'reducers'
import { RouteComponentProps } from 'react-router-dom'
import { connect } from 'react-redux'
import { Table, SelectSearch, Pagination, Input, Select, Button, Tooltip, DatePicker, Modal } from 'components'
import { checkPermission, Permissions, Constants, Socket, SocketEvents, Globals, Print, Currency } from 'utils'
import { ReportService } from 'services'
import { AdjuntoIcon, ViewIcon } from 'assets/icons'
import moment from 'moment'
import hljs from 'highlight.js/lib/core'
import jsonHighlight from 'highlight.js/lib/languages/json'
import 'highlight.js/styles/atom-one-light.css'

hljs.registerLanguage('json', jsonHighlight)

interface LocationProps {
  page: number | undefined
  form: any
}

interface Props extends RouteComponentProps<{}, {}, LocationProps> {
  dispatch: any
}

const INITIAL_STATE = {
  search: '',
  since: '',
  until: '',
  user_id: '',
  module_id: '',
}

const { ACTIVE, INACTIVE, ACTIVE_LABEL, INACTIVE_LABEL, VERIFIED, VERIFIED_LABEL } = Constants.USERS_STATUS

const STATUS = [
  { value: ACTIVE.toString(), label: ACTIVE_LABEL },
  { value: INACTIVE.toString(), label: INACTIVE_LABEL },
  { value: VERIFIED.toString(), label: VERIFIED_LABEL },
]

const MODULES: { [key: string]: string } = {
  'Property': 'Propiedades',
  'Price': 'Precios',
  'User': 'Usuarios',
}

const CONCEPT: { [key: string]: string } = {
  'updated': 'Actualización',
  'created': 'Creación',
}

class Report extends React.Component<Props> {
  timer: any = undefined

  state = {
    data: [],
    page: 1,
    last_page: 1,
    header: ['Fecha Hora', 'Nombre del evento', 'Realizado por', 'Modulo', 'Detalle', 'Concepto', 'Acciones'],
    form: INITIAL_STATE,
    visible: false,
    clients: [],
    client: null,
    conversion_dolar: 0,
    form_search: '',
    modules: [],
    module: null,
    report: null,
    reportHeader: ['Campo', 'Dato anterior', 'Dato nuevo'],
  }

  async componentDidMount() {
    this.props.dispatch({
      type: 'SET_HEADER',
      payload: {
        title: 'Reporte de Auditoria',
        onBack: null,
      },
    })

    await this.setState({
      page: this.props.location.state?.page || this.state.page,
      form: this.props.location.state?.form || this.state.form,
    })

    this.load()
  }

  load = async (withoutLoading: boolean = false) => {
    const client: any = this.state.client
    const module: any = this.state.module

    const res: any = await ReportService.admin.audit_report({
      page: this.state.page,
      withoutLoading,
      ...this.state.form,
      client_id: client?.id || '',
      module_id: module?.id || '',
    })

    this.setState({
      data: res.movements.data,
      last_page: res.movements.last_page,
      clients: res.users,
      modules: res.modules,
    })
  }

  change = (e: any, callback = () => {}) => {
    this.setState(
      {
        page: 1,
        form: {
          ...this.state.form,
          [e.target.name]: e.target.value,
        },
      },
      callback
    )
  }

  reset = () => {
    this.setState(
      {
        module: '',
        client: '',
        form: INITIAL_STATE,
        page: 1,
      },
      () => this.load(true)
    )
  }

  print = (e: any, item: any) => {
    e.preventDefault()
  }

  view = (e: any, item: any) => {
    e.preventDefault()
    const skipKeys = ['password']
    let old: any = {}
    let attributes: any = {}

    if (item?.properties?.old) {
      const oldKeys = Object.keys(item?.properties?.old)
      let k = -1
      while (++k < oldKeys.length) {
        const key = oldKeys[k]
        if (!skipKeys.includes(key)) {
          // if (item.properties.old[key] !== item.properties.attributes[key]) {
          // }
          old[key] = item.properties.old[key]
          attributes[key] = item.properties.attributes[key]
        }
      }
    } else {
      old = null
      attributes = item.properties.attributes
    }

    item.properties.old = old
    item.properties.attributes = attributes

    this.setState({
      visible: true,
      report: item,
      // client: item,
      // modules: [] ?? item,
    })
  }

  onClose = () => {
    this.setState({
      visible: false,
    })
  }

  getTextHighlight = (obj: any) => {
    const text = JSON.stringify(obj, null, 2)
    return hljs.highlight(text, { language: 'json' }).value
  }

  render() {
    const { clients } = this.state
    const client: any = this.state.client

    const { modules } = this.state
    const module: any = this.state.module

    const { visible } = this.state
    const report: any = this.state.report

    const reportDataKeys = report
      ? report?.properties?.old ? Object.keys(report?.properties?.old) : Object.keys(report?.properties?.attributes)
      : []

    return (
      <div id="audit-report">
        <Modal className="high-modal modal-detail-audit-report" visible={visible} onClose={this.onClose} title="Detalle del reporte">
          {report && (
            <div className="row">
              <div className="col-12">
                <Table data={reportDataKeys.length} title="" header={this.state.reportHeader}>
                  {reportDataKeys.map((key: any, index: number) => {
                    return (
                      <tr key={index}>
                        <td>{key}</td>
                        <td>{report?.properties?.old?.[key] ?? '-'}</td>
                        <td>{report?.properties?.attributes?.[key] ?? '-'}</td>
                      </tr>
                    )
                  })}
                </Table>
              </div>
            </div>
          )}
        </Modal>

        <div className="row">
          <div className="col-md-3">
            <DatePicker
              color="white"
              label="Desde"
              maxDate={moment().toDate()}
              value={this.state.form.since}
              onChange={(value: string) =>
                this.change(
                  {
                    target: { name: 'since', value },
                  },
                  () => this.load(true)
                )
              }
            />
          </div>

          <div className="col-md-3">
            <DatePicker
              color="white"
              label="Hasta"
              maxDate={moment().toDate()}
              value={this.state.form.until}
              onChange={(value: string) =>
                this.change(
                  {
                    target: { name: 'until', value },
                  },
                  () => this.load(true)
                )
              }
            />
          </div>

          <div className="col-md-3">
            <Input
              color="white"
              value={client?.full_name}
              name="client"
              label="Detalle"
              placeholder="Detalle"
              onChange={(e: any) => {
                this.setState(
                  {
                    page: 1,
                    form: {
                      ...this.state.form,
                      detail: e.target.value,
                    },
                    client: {
                      id: e.target.value,
                      full_name: e.target.value,
                    },
                  },
                  () => this.load(true)
                )
              }}
              style={{ borderRadius: '3px' }}
            />
          </div>

          <div className="col-md-3">
            <SelectSearch
              label="Módulo"
              onChange={(module: any) => {
                this.setState(
                  {
                    page: 1,
                    form: {
                      ...this.state.form,
                      module_id: module.id,
                    },
                    module: {
                      id: module.value,
                      name: module.label,
                    },
                  },
                  () => this.load(true)
                )
              }}
              placeholder="Seleccionar Módulo"
              value={{
                value: module?.id || '',
                label: module?.name || '',
              }}
              options={modules?.map((i: any) => {
                return {
                  value: i.id,
                  label: i.name,
                }
              })}
            />
          </div>
        </div>

        <div className="row area-movil">
          <div className="col-md-2">
            {this.state.data.length > 0 && (
              <div>
                <a
                  href={
                    Constants.BASE_API +
                    'admin/reports/audit_report/excel?' +
                    Globals.getParams({
                      ...this.state.form,
                      module_id: module?.id || '',
                      client_id: client?.id || '',
                    })
                  }
                >
                  <Button className="btn-blue-create">Exportar Excel</Button>
                </a>
              </div>
            )}
          </div>
          {/* <div className="col-md-2">
            {this.state.data.length > 0 && (
              <div>
                <a
                  href={
                    Constants.BASE_API +
                    'admin/reports/audit_report/pdf?' +
                    Globals.getParams({
                      ...this.state.form,
                      module_id: module?.id || '',
                      client_id: client?.id || '',
                    })
                  }
                >
                  <Button className="btn-blue-create">Exportar PDF</Button>
                </a>
              </div>
            )}
          </div> */}
        </div>

        {this.state.form != INITIAL_STATE && (
          <p className="btn-reset" style={{ marginTop: '15px' }} onClick={this.reset}>
            Limpiar filtros
          </p>
        )}

        <Table data={this.state.data.length} title="" header={this.state.header}>
          {this.state.data.map((i: any, index: number) => {
            return (
              <tr key={index}>
                <td>{moment(i.created_at).format('DD/MM/YYYY HH:mm')}</td>
                <td>{i.log_name}</td>
                <td>{i.causer?.username ?? 'Sistema'}</td>
                <td>{i.subject_type ? MODULES[i.subject_type.split('\\').pop()] ?? i.subject_type : i.subject_type}</td>
                <td>{i.detail ? i.detail : ''}</td>
                <td>{i.description ? CONCEPT[i.description] ?? i.description : i.description}</td>
                <td>
                  <Tooltip title="Ver detalles">
                    <a className="link-icon" href="#" onClick={(e: any) => this.view(e, i)}>
                      <img src={ViewIcon} />
                    </a>
                  </Tooltip>
                </td>
              </tr>
            )
          })}
        </Table>

        <div className="row">
          <Pagination
            pages={this.state.last_page}
            active={this.state.page}
            onChange={async (page: number) => {
              await this.setState({
                page: page,
              })
              this.load()
            }}
          />
        </div>
      </div>
    )
  }
}

export default connect()(Report)
