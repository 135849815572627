import React from 'react';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { Table, Pagination, Input, Select, Button, Tooltip, Modal, TooltipCurrency } from 'components';
import { checkPermission, Permissions, Constants, Socket, SocketEvents, Globals, Print, Currency, RegisterMovement } from 'utils';
import { ClientService, PropertyService } from 'services';
import { EditIcon, ViewIcon, TrashIcon, CheckIcon, CloseIcon, PrintIcon, ViewFileIcon, CommentIcon } from 'assets/icons';
import ViewClient from './view-client';
import CreateSolvency from 'screens/clients/urban-cleanliness/create-solvency';
import CreateComment from './create-comment';

interface LocationProps {
  page: number | undefined;
  form: any
}

interface Props extends RouteComponentProps<{},{},LocationProps>, RootState {
  dispatch: any
}

const INITIAL_STATE = {
  search: '',
  status: ''
}

const { ACTIVE, INACTIVE, ACTIVE_LABEL, INACTIVE_LABEL, VERIFIED, VERIFIED_LABEL } = Constants.USERS_STATUS;

const STATUS = [
  { value: ACTIVE.toString(), label: ACTIVE_LABEL },
  { value: INACTIVE.toString(), label: INACTIVE_LABEL },
  { value: VERIFIED.toString(), label: VERIFIED_LABEL },
]

class Clients extends React.Component<Props> {  

  timer: any = undefined;

  state: any = {
    data: [],
    page: 1,
    last_page: 1,
    header: [
      'ID. IT',
      'Usuario',
      'Nombre y apellido',
      'C.I. / RIF',
      'Teléfono',
      'Correo',
      'Verificado',
      // 'Deuda',
      'Estado',
      'Acciones'
    ],
    form: INITIAL_STATE,
    visible: false,
    client: null,
    conversion_petro: 0,
    visible_solvency: false,
    user_solvency: null,

    visible_comment: false,
    user_comment: null,
  }

  async componentDidMount() {
    this.props.dispatch({
      type: 'SET_HEADER',
      payload: {
        title: 'Contribuyentes',
        onBack: null
      }
    });

    await this.setState({
      page: this.props.location.state?.page || this.state.page,
      form: this.props.location.state?.form || this.state.form
    });

    this.load();
  }

  load = async (withoutLoading: boolean = false) => {
    const res: any = await ClientService.admin.get({
      page: this.state.page,
      without: ['codes_urbaser', 'debt'],
      withoutLoading,
      ...this.state.form
    });
    this.setState({
      data: res.users.data,
      last_page: res.users.last_page,
      conversion_petro: res.conversion_petro?.amount || 0,
    });
  }

  create = () => {
    this.props.history.push({
      pathname: '/admin/users/clients/create',
      state: this.state
    });
  }

  edit = (e: any, url: string) => {
    e.preventDefault();
    this.props.history.push({
      pathname: url,
      state: this.state
    });
  }

  change = (e: any,callback = () => {}) => {
    this.setState({
      page: 1,
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value
      }
    },callback);
  }

  reset = () => {
    this.setState({
      form: INITIAL_STATE,
      page: 1
    },() => this.load(true));
  }

  view = (e: any, item: any) => {
    e.preventDefault();
    this.setState({
      visible: true,
      client: item
    });
  }

  delete = (e: any, item: any) => {
    e.preventDefault();
    Globals.confirm('¿Desea eliminar este usuario?',async () => {
      await ClientService.admin.delete({
        id: item.id
      });
      Globals.showSuccess("Se ha eliminado correctamente el usuario");
      /* Socket.emit(SocketEvents.USERS.DELETE,{
        id: item.id,
      }); */
      this.load();

      RegisterMovement.save({
          value_id: item.id,
          user_id: this.props.user.id,
          module_id: Constants.MODULES.CLIENTS,
          description: `Eliminación del contribuyente ${ item?.full_name } ${ item?.full_document }`
      });
    });
  }

  print = (e: any, item: any) => {
    e.preventDefault();
    Print(ClientService.admin.download(item.crypt),true);
  }

  changeStatus = (e: any, i: any) => {
    e.preventDefault();
    Globals.confirm(`¿Desea ${ i.status == ACTIVE ? 'desactivar' : 'activar' } este usuario?`,async () => {
      await ClientService.admin.status({
        id: i.id,
        status: i.status == ACTIVE ? INACTIVE : ACTIVE
      });
      Globals.showSuccess("Se ha modificado correctamente el usuario");
      Socket.emit(SocketEvents.USERS.CHANGE_STATUS,{
        id: i.id,
      });
      this.load();
    });
  }

  onClose = () => {
    this.setState({
      visible: false
    }, () => {
      if (this.state.form.search || this.state.form.status) {
        this.load();
      }
    });
  }

  requestSolvency = async (e: any, item: any) => {
    e.preventDefault();
    try {
      const data: any = await ClientService.admin.getProperties({ user_id: item.id, withoutLoading: true });

      const properties = data.properties?.map((p: any) => ({
        value: p.id,
        label: p.urbaser_code,
        ...p,
      }));
      this.setState({
        visible_solvency: true,
        user_solvency: { ...item, properties },
      });
    } catch (error) {
      console.log(error);
    }
  }

  onCloseSolvency = () => {
    this.setState({
      visible_solvency: false,
      user_solvency: null,
    });
  }

  openAddComment = (e: any, client: any) => {
    e.preventDefault();

    this.setState({
      visible_comment: true,
      user_comment: client,
    });
  }

  onCloseComment = () => {
    this.setState({
      visible_comment: false,
      user_comment: null,
    });
    this.load();
  }

  importExcel = async (e: any) => {
    const file = e.target.files[0];

    if (!file) return

    try {
      const form = new FormData();
      form.append('file', file);
      await ClientService.admin.importExcel(form)

      Globals.showSuccess('Se ha importado correctamente los datos');
    } catch (error) {
      Globals.showError('Ha ocurrido un error al importar los datos');
    } finally {
      const input = document.getElementById('import-excel') as any
      if (input) {
        input.value = null
      }
    }
  }

  // onDownload = async () => {
  //   try {
  //     const response = await ClientService.admin.downloadListExel(Globals.getParams(this.state.form));
  //     console.log(response);
  //   } catch (error) {
  //     console.log({ error });
  //   }
  // }

  render() {
    const { visible } = this.state;

    return (
        <div id="admin-clients">
            <Modal
                className="high-modal modal-view-client"
                visible={ visible }
                onClose={ this.onClose } 
                title="Ver Contribuyente"
            >
              <ViewClient
                client={ this.state.client }
                history={ this.props.history }
              />
            </Modal>
            <Modal
              className="modal-create-solvency"
              visible={ this.state.visible_solvency }
              onClose={ this.onCloseSolvency }
              title="Generar Solvencia de Aseo Urbano"
            >
              <CreateSolvency
                user={this.state.user_solvency}
                properties={this.state.user_solvency?.properties}
                onClose={this.onCloseSolvency}
              />
            </Modal>

            <Modal
              className="modal-create-comment"
              visible={ this.state.visible_comment }
              onClose={ this.onCloseComment }
              title="Escribir un comentario"
            >
              <CreateComment
                user={this.state.user_comment}
                onClose={this.onCloseComment}
              />
            </Modal>

            <div className="row row-filter">
              <div className="col-12 text-right">
                  {
                    checkPermission(Permissions.CLIENTS.CREATE) && (
                      <Button className="btn-urbaser-green" onClick={ this.create }>
                        Registrar nuevo
                      </Button>
                    )
                  }
                </div>
                <div className="col-md-12">
                  <div className="row row-search">
                    <div className="col-md-3">
                      <Select
                        color="white"
                        name="status"
                        onChange={ (e: any) => this.change(e,() => {
                          this.load(true);
                        }) }
                        placeholder="Estatus"
                        value={ this.state.form.status }
                        options={ STATUS } />
                    </div>

                    <div className="col-md-5">
                      <Input
                        color="white"
                        searchRight
                        value={ this.state.form.search }
                        name="search"
                        placeholder="Buscar Código, Código, Usuario, Nombre, CI"
                        onChange={ (e: any) => {
                          clearTimeout(this.timer);
                          this.timer = setTimeout(() => {
                            this.load(true);
                          },1000);
                          this.change(e); 
                        } } />
                    </div>
                 
                  </div>
                </div>
              </div>

              {
                this.state.data.length > 0 && (
                  <div>
                    {/* <Button className="btn-blue-create" onClick={ () => this.onDownload() }>
                      Exportar Excel
                    </Button> */}
                    <a href={ Constants.BASE_API + 'admin/clients/excel?' + Globals.getParams(this.state.form) }>
                      <Button className="btn-blue-create">
                        Exportar Excel
                      </Button>
                    </a>
                    <Button className="btn-blue-create ml-3" onClick={() => {
                      document.getElementById('import-excel')?.click()
                    }}>
                      Importar Excel
                    </Button>
                    <input id="import-excel" type="file" style={{ display: 'none' }} onChange={this.importExcel} />
                  </div>
                )
              }

              {
                this.state.form != INITIAL_STATE && (
                  <p className="btn-reset" style={ { marginTop: '15px' } } onClick={ this.reset }>Limpiar filtros</p>
                )
              }  

              <Table data={ this.state.data.length } title="" header={ this.state.header }>
                { this.state.data.map((i: any,index: number) => {
                  return (
                    <tr key={ index }>
                      <td>{ i.number_format }</td>
                      {/* <td>{ i.properties?.map((i: any) => i.urbaser_code).join(', ') }</td> */}
                      <td>{ i.username }</td>
                      <td className="text-nowrap">{ i.full_name }</td>
                      <td className="text-nowrap">{ i.full_document }</td>
                      <td>{ i.full_phone }</td>
                      <td>{ i.email }</td>
                      <td>{ i.verified == Constants.USERS_VERIFIED.VERIFIED ? 'Verificado' : 'Sin Verificar' }</td>
                      {/* <td className="no-wrap"><TooltipCurrency title={ i.debt_total }>{ Globals.formatMiles(Currency.ConvertAux(i.debt_total,this.state.conversion_petro),true,Currency.BOLIVAR) }</TooltipCurrency></td> */}
                      <td>{ i.status_text }</td>
                      <td className="icon-actions">
                          <Tooltip title="Ver">
                            <a className="link-icon" href="#" onClick={ (e: any) => this.view(e,i) }>
                              <img src={ ViewIcon } />
                            </a>
                          </Tooltip>
                          {/* {
                            checkPermission(Permissions.CLIENTS.CREATE) && (
                              <Tooltip title="Editar">
                                <a className="link-icon" href="#" onClick={ (e: any) => this.edit(e,`/admin/users/clients/edit/${ i.id }`) }>
                                  <img src={ EditIcon } />
                                </a>
                              </Tooltip>
                            )
                          } */}
                          <Tooltip title={ i.status == ACTIVE ? 'Desactivar' : 'Activar' }>
                            <a className="link-icon" href="#" onClick={ (e: any) => this.changeStatus(e,i) }>
                              <img src={ i.status == ACTIVE ? CloseIcon : CheckIcon } />
                            </a>
                          </Tooltip>
                          <Tooltip title="Imprimir">
                            <a className="link-icon" href="#" onClick={ (e: any) => this.print(e,i) }>
                              <img src={ PrintIcon } />
                            </a>
                          </Tooltip>
                          {
                            checkPermission(Permissions.CLIENTS.DELETE) && (
                              <Tooltip title="Eliminar">
                                <a className="link-icon" href="#" onClick={ (e: any) => this.delete(e,i) }>
                                  <img src={ TrashIcon } />
                                </a>
                              </Tooltip>
                            )
                          }
                          <Tooltip title="Solicitar Solvencia">
                            <a className="link-icon" href="#" onClick={ (e: any) => this.requestSolvency(e, i) }>
                              <img src={ ViewFileIcon } />
                            </a>
                          </Tooltip>
                          <Tooltip title="Comentario">
                            <a className="link-icon" href="#" onClick={ (e: any) => this.openAddComment(e, i) }>
                              <img src={ CommentIcon } />
                            </a>
                          </Tooltip>
                      </td>
                    </tr>
                  )
                }) }
              </Table>

              <Pagination 
                pages={ this.state.last_page } 
                active={ this.state.page }
                onChange={ async (page: number) => {
                  await this.setState({
                    page: page
                  });
                  this.load();
              } } />
        </div>        
    )
  }
}

export default connect((state: RootState) => {
  return {
    user: state.user
  }
})(Clients);