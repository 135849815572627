import React, { useEffect } from "react";
import { Provider, useSelector, useDispatch } from "react-redux";
import { store, persistor } from "./store";
import { PersistGate } from "redux-persist/integration/react";
import {
  HashRouter as Router,
  Switch,
  Redirect,
  useHistory 
} from "react-router-dom";
import "moment/locale/es";
import { Route, Modal } from "components";
import { RootState } from "reducers";
import { Permissions, Constants, Globals, Socket, SocketEvents } from 'utils';
import { AuthService } from 'services';

// Public
import Home from 'screens/home';

// Welcome
import WelcomeUsers from 'screens/welcome';

// Auth
import Login from "screens/auth/login/login";
import Password from "screens/password";
import Reset from "screens/auth/login/reset";
import Recover from "screens/auth/login/recover";
import Register from "screens/auth/register/register";
import Profile from "screens/profile";
import ProfileAdmin from 'screens/admin/profile';
import ProfileClient from 'screens/clients/profile';

// Properties (Client)
import ClientProperties from 'screens/clients/properties/properties';
import ClientCreateProperty from 'screens/clients/properties/create-property';
import ClientEditProperty from 'screens/clients/properties/edit-property';

// Account Status (Client)
import ClientAccountStatus from 'screens/clients/account-status/account-status';

// Suggestions (Client)
import ClientSuggestions from 'screens/clients/complaints-suggestions/complaints-suggestions';
import ClientViewSuggestion from 'screens/clients/complaints-suggestions/view-suggestion';

// Historial de Comprobantes (Client)
import ClientVoucherHistory from 'screens/clients/voucher-history/voucher-history';

// Payments (Client)
import ClientPayments from 'screens/clients/payments/payments';

// Routes Schedules (Client)
import ClientRouteSchedules from 'screens/clients/routes-schedules/routes-schedules';

// Urban Cleanliness (Client)
import ClientUrbanCleanliness from 'screens/clients/urban-cleanliness/urban-cleanliness';

// Payments Agreements (Client)
import ClientPaymentsAgreements from 'screens/clients/payment-agreements/payment-agreements';

// Registro de usuarios
import AdminUsers from 'screens/admin/users/users';
import AdminClients from 'screens/admin/users/clients';
import AdminModerators from 'screens/admin/users/moderators';
import AdminEditModerator from 'screens/admin/users/edit-moderator';
import AdminCreateModerator from 'screens/admin/users/create-moderator';
import AdminCreateClient from 'screens/admin/users/create-client';
import AdminCreateClientProperty from 'screens/admin/users/create-property';
import AdminEditClient from 'screens/admin/users/edit-client';

// Tarifas
import AdminTariff from 'screens/admin/tariff/tariff';

// Estado de Cuenta
import AdminAccountStatus from 'screens/admin/account-status/account-status';

// Cuentas Bancarias
import AdminBankAccounts from 'screens/admin/bank-accounts/bank-accounts';

// Caja
import AdminCash from 'screens/admin/cash/cash';
import AdminCashNew from 'screens/admin/cash/cash-new';

// Reportes
import AdminReports from 'screens/admin/reports/reports';
import SalesBook from 'screens/admin/reports/sales-book';
import OverdueCollectionReport from 'screens/admin/reports/overdue-collection-report';
import IncomeReport from 'screens/admin/reports/income-report';
import ReportAgreements from 'screens/admin/reports/report-agreements';
import RetentionReport from 'screens/admin/reports/retention-report';
import BankReconciliation from 'screens/admin/reports/bank-reconciliation';
import VoidedInvoices from 'screens/admin/reports/voided-invoices';
import AuditReport from 'screens/admin/reports/audit-report';

// Historial de Comprobantes
import AdminVoucherHistory from 'screens/admin/voucher-history/voucher-history';

// Solvencias de Aseo Urbano
import AdminUrbanCleaningSolvencies from 'screens/admin/urban-cleaning-solvencies/urban-cleaning-solvencies';

// Dudas y Sugerencias
import AdminComplaintsSuggestions from 'screens/admin/complaints-suggestions/complaints-suggestions';
import AdminViewSuggestion from 'screens/admin/complaints-suggestions/view-suggestion';

// Convenios de Pagos
import AdminPaymentAgreements from 'screens/admin/payment-agreements/payment-agreements';

// Pagos
import AdminPayments from 'screens/admin/payments/payments';

// Rutas y Horarios
import AdminRoutesSchedules from 'screens/admin/routes-schedules/routes-schedules';

// Scripts
import "jquery/dist/jquery.min.js";
import "bootstrap/dist/js/bootstrap.min.js";
import { emit, on } from "jetemit";

// Styles
import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";
import "./scss/main.scss";

const Container = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    const check = async () => {     
      const user = store.getState().user;
      if (user) {
        const res: any = await AuthService.check({
          user_id: user.id,
          withoutLoading: true
        }).catch(err => console.log(err));

        if (res.user) {
          if (res.user.status == Constants.USERS_STATUS.INACTIVE) {
            Globals.showError("Lo sentimos, su usuario ha sido desactivado");
            close();
          }
          else {
            dispatch({
              type: 'SET_USER',
              payload: res.user
            });
          }
        }
        else if (res.delete) {
          Globals.showError("Lo sentimos, su usuario ha sido eliminado");
          close();
        }
      }
    }

    const checkBadge = async () => {
      const user = store.getState().user;
      if (user) {
        const res: any = await AuthService.checkBadge({
          user_id: user.id,
          withoutLoading: true
        });
        dispatch({
          type: 'SET_BADGE',
          payload: res
        });
      }
    }

    const close = async () => {
      await dispatch({
        type: 'SET_USER',
        payload: null
      }); 
      history.replace('/logout');
    }

    check();

   /*  Socket.on(SocketEvents.USERS.CHANGE_STATUS,(data: any) => {
      const user = store.getState().user;
      if (user && data.id == user.id) {
        check()
      }
    });
    Socket.on(SocketEvents.USERS.DELETE,(data: any) => {
      const user = store.getState().user;
      if (user && data.id == user.id) {
        check()
      }
    });
    Socket.on(SocketEvents.USERS.UPDATE,(data: any) => {
      const user = store.getState().user;
      if (user && data.id == user.id) {
        check()
      }
    });
    Socket.on(SocketEvents.MESSAGES.SEND,(data: any) => {
      const chat = store.getState().chat;
      if (chat != data.suggestion_id) {
        checkBadge();
      }
      emit(SocketEvents.MESSAGES.SEND,data);
    });
    Socket.on(SocketEvents.MESSAGES.CREATE,(data: any) => {
      checkBadge();
      emit(SocketEvents.MESSAGES.CREATE,data);
    });
    Socket.on(SocketEvents.MESSAGES.DELETE,(data: any) => {
      checkBadge();
      emit(SocketEvents.MESSAGES.DELETE,data);
    });
    Socket.on(SocketEvents.MESSAGES.STATUS,(data: any) => {
      emit(SocketEvents.MESSAGES.STATUS,data);
    });
    Socket.on(SocketEvents.PETRO.CHANGE,(data: any) => {
      emit(SocketEvents.PETRO.CHANGE,data);
    });

    on(SocketEvents.BADGE.UPDATE,() => {
      checkBadge();
    }); */

  },[]);

  return (
    <React.Fragment>
      <Switch>
        {/* Public */}
        <Route exact path="/" component={ Home } />

        {/* Auth */}
        <Route exact noAuth path="/login" component={ Login } />
        <Route exact auth path="/password" component={ Password } />
        <Route exact auth path="/profile" component={ Profile } />
        <Route exact admin path="/admin/profile" component={ ProfileAdmin } />
        <Route exact client path="/client/profile" component={ ProfileClient } />
        <Route exact noAuth path="/recover" component={ Recover } />
        <Route exact noAuth path="/recover/:code" component={ Reset } />
        <Route exact noAuth path="/register" component={ Register } />        

        {/* Users */}
        <Route exact admin path="/admin/users/clients" component={ AdminClients } permission={ Permissions.CLIENTS.VIEW } />
        <Route exact admin path="/admin/users/moderators" component={ AdminModerators } permission={ Permissions.MODERATORS.VIEW } />
        <Route exact admin path="/admin/users/moderators/edit/:id" component={ AdminEditModerator } permission={ Permissions.MODERATORS.CREATE } />
        <Route exact admin path="/admin/users/moderators/create" component={ AdminCreateModerator } permission={ Permissions.MODERATORS.CREATE } />
        <Route exact admin path="/admin/users/clients/edit/:id/:propertyId?" component={ AdminEditClient } permission={ Permissions.CLIENTS.CREATE } />
        <Route exact admin path="/admin/users/clients/create" component={ AdminCreateClient } permission={ Permissions.CLIENTS.CREATE } />
        <Route exact admin path="/admin/users/clients/:id/create-property/:propertyId?" component={ AdminCreateClientProperty } permission={ Permissions.CLIENTS.CREATE } />
        <Route exact admin path="/admin/users" component={ AdminUsers } />
        <Route exact auth path="/welcome" component={ WelcomeUsers } />

        {/* Tarifas */}
        <Route exact admin path="/admin/tariff" component={ AdminTariff } permission={ Permissions.TARIFF.VIEW } />

        {/* Estado de Cuenta */}
        <Route exact admin path="/admin/account-status/:propertyId?" component={ AdminAccountStatus } permission={ Permissions.ACCOUNT_STATUS.VIEW_RECORD } />

        {/* Pagos */}
        <Route exact admin path="/admin/payments" component={ AdminPayments } permission={ Permissions.PAYMENTS.VIEW } />

        {/* Reportes */}
        <Route exact admin path="/admin/reports" component={ AdminReports } permission={ Permissions.REPORTS.VIEW } />
        <Route exact admin path="/admin/reports/sales-book" component={ SalesBook } permission={ Permissions.REPORTS.VIEW } />
        <Route exact admin path="/admin/reports/overdue-collection-report" component={ OverdueCollectionReport } permission={ Permissions.REPORTS.VIEW } />
        <Route exact admin path="/admin/reports/income-report" component={ IncomeReport } permission={ Permissions.REPORTS.VIEW } />
        <Route exact admin path="/admin/reports/report-agreements" component={ ReportAgreements } permission={ Permissions.REPORTS.VIEW } />
        <Route exact admin path="/admin/reports/retention-report" component={ RetentionReport } permission={ Permissions.REPORTS.VIEW } />
        <Route exact admin path="/admin/reports/bank-reconciliation" component={ BankReconciliation } permission={ Permissions.REPORTS.VIEW } />
        <Route exact admin path="/admin/reports/voided-invoices" component={ VoidedInvoices } permission={ Permissions.REPORTS.VIEW } />
        <Route exact admin path="/admin/reports/audit-report" component={ AuditReport } permission={ Permissions.REPORTS.VIEW } />

        {/* Historial de Comprobantes */}
        <Route exact admin path="/admin/voucher-history" component={ AdminVoucherHistory } permission={ Permissions.VOUCHER_HISTORY.VIEW } />

        {/* Dudas y Sugerencias */}
        <Route exact admin path="/admin/complaints-suggestions" component={ AdminComplaintsSuggestions } permission={ Permissions.COMPLAINTS_SUGGESTIONS.VIEW } />
        <Route exact admin path="/admin/complaints-suggestions/view/:id" component={ AdminViewSuggestion } permission={ Permissions.COMPLAINTS_SUGGESTIONS.VIEW } />

        {/* Rutas y Horarios */}
        <Route exact admin path="/admin/routes-schedules" component={ AdminRoutesSchedules } permission={ Permissions.ROUTES_SCHEDULES.VIEW } />

        {/* Cuentas Bancarias */}
        <Route exact admin path="/admin/bank-accounts" component={ AdminBankAccounts } permission={ Permissions.BANK_ACCOUNTS.VIEW } />

        {/* Solvencias Aseo Urbano */}
        <Route exact admin path="/admin/urban-cleaning-solvencies" component={ AdminUrbanCleaningSolvencies } permission={ Permissions.URBAN_CLEANLINESS.VIEW } />

        {/* Convenios de Pagos */}
        <Route exact admin path="/admin/payment-agreements" component={ AdminPaymentAgreements } permission={ Permissions.PAYMENT_AGREEMENTS.VIEW } />

        {/* Caja */}
        <Route exact admin path="/admin/cash/:id?" component={ AdminCash } permission={ Permissions.CASH.VIEW } />
        <Route exact admin path="/admin/cash-new/:id?" component={ AdminCashNew } permission={ Permissions.CASH.VIEW } />
        <Route exact client path="/cash/:id?" component={ AdminCashNew } />

        {/* Estado de Cuenta (Client) */}
        <Route exact client path="/account-status" component={ ClientAccountStatus } />

        {/* Historial de Comprobantes */}
        <Route exact client path="/voucher-history" component={ ClientVoucherHistory }/>

        {/* Sugerencias (Client) */}
        <Route exact client path="/complaints-suggestions" component={ ClientSuggestions } />
        <Route exact client path="/complaints-suggestions/view/:id" component={ ClientViewSuggestion } />

        {/* Payments (Client) */}
        <Route exact client path="/payments" component={ ClientPayments } />

        {/* Aseo Urbano (Client) */}
        <Route exact client path="/urban-cleanliness" component={ ClientUrbanCleanliness } />

        {/* Rutas y Horarios (Client) */}
        <Route exact client path="/routes-schedules" component={ ClientRouteSchedules } />

        {/* Inmuebles (Client) */}
        <Route exact client path="/properties" component={ ClientProperties } />
        {/* <Route exact client path="/properties/create" component={ ClientCreateProperty } /> */}
        <Route exact client path="/properties/edit/:id" component={ ClientEditProperty } />

        {/* Convenio de Pagos (Client) */}
        <Route exact client path="/payment-agreements" component={ ClientPaymentsAgreements } />

        <Redirect to="/" />
      </Switch>
    </React.Fragment>
  )
}

const App = () => (
  <Provider store={ store }>
    <PersistGate loading={ null } persistor={ persistor }>
      <Router>
        <Container />
      </Router>
    </PersistGate>
  </Provider>
);

export default App;
