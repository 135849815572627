import React from 'react';
import { Link } from 'react-router-dom';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { Table, Pagination, Input, Select, Button, Tooltip, DatePicker, Modal, TooltipCurrency } from 'components';
import { Constants, Globals, Currency, Print, Permissions, checkPermission } from 'utils';
import { AccountStatusService, PropertyService } from 'services';
import { ViewIcon, PrintIcon, CheckWhite, RefreshIcon, SendIcon, HouseIcon, NotifyIcon } from 'assets/icons';
import ViewProperty from './view-property';
import EditPendingMonths from './edit-pending-months';
import moment from 'moment';
import Axios from 'axios';
import Swal from 'sweetalert2';
import colors from './../../../utils/colors';


interface LocationProps {
  page: number | undefined;
  form: any
}
interface Params {
	propertyId: string | undefined
}
interface Props extends RootState, RouteComponentProps<Params> {
  dispatch: any
}

const INITIAL_SEARCH = {
  since: '',
  until: '',
  status: '',
  type: '',
  search: ''
}

const { RECONCILED, TO_RECONCILE, NON_PAYMENT, RECONCILED_LABEL, TO_RECONCILE_LABEL, NON_PAYMENT_LABEL } = Constants.ACCOUNT_STATUS;
const { VIEW_RECORD, VIEW_SEND_EMAIL, VIEW_CALCULATE } = Permissions.ACCOUNT_STATUS;
const { CONDOMINIUM } = Constants.PROPERTY_USES

const STATUS = [
  { value: RECONCILED.toString(), label: RECONCILED_LABEL },
  { value: TO_RECONCILE.toString(), label: TO_RECONCILE_LABEL },
  { value: NON_PAYMENT.toString(), label: NON_PAYMENT_LABEL }
]

class AccountStatus extends React.Component<Props> {

  timer: any = undefined;

  state: any = {
    data: [],
    page: 1,
    last_page: 1,
    header: [
      'ID. IT',
      'Usuario',
      'Código',
      'Tipo',
      'Fecha',
      'Datos Fiscales',
      'Monto Adeudado',
      'Meses Pendientes',
      'Notificado',
      'Acciones'
    ],
    references: {
      types: [],
      conversion_dolar: {
        amount: 0
      },
      conversion_petro: {
        amount: 0
      }
    },
    form: INITIAL_SEARCH,
    visible: false,
    property: null,
    visiblePendingMonths: false,
    property_selected: null,
  }

  componentDidMount() {
    this.props.dispatch({
      type: 'SET_HEADER',
      payload: {
        title: 'Record de Cuenta',
        onBack: null
      }
    });
    this.getReferences();
    this.load();
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<{}>, snapshot?: any): void {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.setState({
        page: 1,
        last_page: 1,
        form: INITIAL_SEARCH,
      }, () => {
        this.getReferences();
        this.load();
      });
    }
  }

  getReferences = async () => {
    const references: any = await AccountStatusService.admin.getReferences();
    this.setState({
      references: references
    });
  }

  load = async (withoutLoading: boolean = false) => {
    const res: any = await AccountStatusService.admin.get({
      page: this.state.page,
      id: this.props.match.params.propertyId,
      withoutLoading,
      ...this.state.form
    });
    this.setState({
      data: res.properties.data,
      last_page: res.properties.last_page,
      property_selected: res.property_selected ?? null
    });
  }

  change = async (e: any, callback = () => { }) => {
    await this.setState({
      page: 1,
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value
      }
    }, callback);
  }

  onClose = () => {
    this.setState({
      visible: false
    });
  }

  view = (e: any, item: any) => {
    e.preventDefault();
    this.setState({
      visible: true,
      property: item
    });
  }

  pendingMonths = (e: any, item: any) => {
    e.preventDefault();

    this.setState({
      visiblePendingMonths: true,
      property: item
    });
  }

  onClosePendingMonths = () => {
    this.setState({
      visiblePendingMonths: false
    }, () => this.load(true));
  }

  print = (e: any, item: any) => {
    e.preventDefault();

    if (item.property_use_id !== CONDOMINIUM) {
      Print(AccountStatusService.admin.download(item.crypt), true);
    } else {
      window.open(AccountStatusService.admin.download(item.crypt))
    }
  }

  toggleCanBeSent = async (e: any, item: any) => {
    e.preventDefault();
    // if (item.canBeSent) {
    //   return;
    // }

    const result = await Swal.fire({
      title: "¿Está seguro?",
      text: "Esta opción cambiará el estatus de envío de correo",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: colors.swal,
    });

    if (result.value) {
      await PropertyService.checkSend(item.id);

      const data = [...this.state.data]
        .map((i: any) => {
          if (i.id === item.id) {
            i.canBeSent = !i.canBeSent;
          }

          return i;
        });

      this.setState(
        { data },
        () => this.load(true)
      );
    }
  }

  toggleWasSent = async (e: any, item: any) => {
    e.preventDefault();
    // if (item.canBeSent) {
    //   return;
    // }

    // if(item.wasSent) {
      
    //   await Swal.fire({
    //     title: "No se puede enviar",
    //     text: "El correo ya fue enviado este mes",
    //     type: "info",
    //     confirmButtonColor: colors.swal,
    //   });

    // }else{

      const result = await Swal.fire({
        title: "Confirmar envío",
        text: "Enviará un correo al contribuyente",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: colors.swal,
      });

      if (result.value) {
        
        const res = await PropertyService.manualSend(item.id);

        if (res.status == 'success') {

          Swal.fire({
            title: "¡Éxito!",
            text: "El email fue enviado",
            type: "success",
            confirmButtonColor: colors.swal,
          });

          const data = [...this.state.data];

          this.setState(
            { data },
            () => this.load(true)
          );

        }else{
          Swal.fire({
            title: "Error",
            text: "No es posible enviar el mail, verifique no haya sido enviado antes o que este marcado para enviar",
            type: "error",
            confirmButtonColor: colors.swal,
          });
        }

        
      }
    // }
  }

  

  toggleNotifySent = async (e: any, item: any) => {
    e.preventDefault();
    const nowDate = moment().format('YYYY-MM-DD') + 'T00:00';

    const result = await Swal.fire({
      title: "Confirmar envío",
      text: "Enviará una notificación al contribuyente",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: colors.swal,
      html: `
        <div id="swal2-content" style="display: block;">Enviará una notificación al contribuyente</div>
        <input id="swal-date" type="datetime-local" class="swal2-input" min="${nowDate}">
      `,
      focusConfirm: false,
      preConfirm: () => {
        return (document.getElementById("swal-date") as HTMLInputElement)?.value;
      }
    });

    if (result.value) {
      const res = await PropertyService.notifySend(item.id, { date: result.value });

      if (res.status == 'success') {

        Swal.fire({
          title: "¡Éxito!",
          text: "El email fue enviado",
          type: "success",
          confirmButtonColor: colors.swal,
        });

        const data = [...this.state.data];

        this.setState(
          { data },
          () => this.load(true)
        );

      }else{
        Swal.fire({
          title: "Error",
          text: "No es posible enviar el mail, verifique no haya sido enviado antes o que este marcado para enviar",
          type: "error",
          confirmButtonColor: colors.swal,
        });
      }

      
    }
  }

  toggleRefresh = async (e: any, item: any) => {
    e.preventDefault();
    // if (item.canBeSent) {
    //   return;
    // }

    const result = await Swal.fire({
      title: "¿Está seguro?",
      text: "Esta opción calculará el estado de cuenta del contribuyente",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: colors.swal,
    });

    if (result.value) {

      const res = await PropertyService.refresh(item.id);

      if (res.status == 'success') {
        console.log(res);
        Swal.fire({
          title: "¡Éxito!",
          text: "El estado de cuenta del contribuyente ha sido calculado",
          type: "success",
          confirmButtonColor: colors.swal,
        });
        const item = res.property;
        const data = [...this.state.data]
          .map((i: any) => {
            if (i.id === item.id) {
              i.total_conversion_record = item.total_conversion_record;
            }

            return i;
          });

        this.setState(
          { data } ,
          () => this.load(true)
        );
      }
    }
  }

  reset = () => {
    this.setState({
      form: INITIAL_SEARCH,
      page: 1
    }, () => this.load(true));
  }

  sendMails = async (e: any) => {
    e.preventDefault();
    
    const result = await Swal.fire({
      title: "Confirmar envío",
      text: "Esta opción enviará correos a los contribuyentes que estén marcados para enviar",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: colors.swal,
    });

    if (result.value) {
      const rs = await PropertyService.sendMails();

      if (rs.status == 'success') {
        Swal.fire({
          title: "¡Éxito!",
          text: "El proceso de envio de correos ha sido iniciado, este proceso puede demorar varios minutos",
          type: "success",
          confirmButtonColor: colors.swal,
        });
      }

    }
  }

  calculate = async (): Promise<any> => {
    // const url = 'http://localhost:11093/cronjob/execute';

    // try {
    //   const response = await fetch(url);
    //   const text = await response.text();

    //   const statuses = [200, 403];

    //   if (statuses.includes(response.status)) {
    //     this.load(true);
    //     Globals.showSuccess(text);
    //   } else {
    //     throw new Error('Lo sentimos, el calculo no pudo ser realizado');
    //   }
    // } catch (e) {
    //   Globals.showError('Lo sentimos, el calculo no pudo ser realizado');
    // }
  }

  render() {
    const { visible, visiblePendingMonths, property_selected } = this.state;

    return (
      <div id="account-status">
        <Modal
          className="high-modal modal-view-account-status"
          visible={visible}
          onClose={this.onClose}
          title="Pagos Pendientes"
        >
          <ViewProperty
            property={this.state.property}
          />
        </Modal>
        <Modal
          className="modal-edit-iva modal-view-pending-months"
          visible={visiblePendingMonths}
          onClose={this.onClosePendingMonths}
          title="Meses Pendientes"
        >
          <EditPendingMonths
            user={this.props.user}
            property={this.state.property}
            onClose={this.onClosePendingMonths}
          />
        </Modal>
        <div className="row">
            { property_selected && (
              <div className="col-md-12 mb-4">
                <Link to={'/admin/account-status'}>
                  <Button className="btn-blue-create btn-filter" >
                    Atras
                  </Button>
                </Link>
             </div>
          )}

          <div className="col-md-4">
            <div className="row">
              <div className="col-md-6">
                <DatePicker label="Desde" value={this.state.form.since}
                  showYearDropdown={true}
                  maxDate={moment(this.state.form.until || moment()).toDate()}
                  onChange={(value: string) => this.change({
                    target: { name: 'since', value }
                  }, () => this.load(true))}
                />
              </div>
              <div className="col-md-6">
                <DatePicker label="Hasta" value={this.state.form.until}
                  showYearDropdown={true}
                  maxDate={moment().toDate()}
                  minDate={this.state.form.since && moment(this.state.form.since).toDate()}
                  onChange={(value: string) => this.change({
                    target: { name: 'until', value }
                  }, () => this.load(true))}
                />
              </div>
            </div>
          </div>
          <div className="col-md-8">
            <div className="row">
              <div className="col-md-6">
                <Select
                  label="Tipo"
                  color="white"
                  name="type"
                  onChange={(e: any) => this.change(e, () => this.load(true))}
                  placeholder="Tipo"
                  value={this.state.form.type}
                  options={this.state.references.types}
                />
              </div>
              <div className="col-md-6">
                <Select
                  label="Estatus"
                  color="white"
                  name="status"
                  onChange={(e: any) => this.change(e, () => this.load(true))}
                  placeholder="Estatus"
                  value={this.state.form.status}
                  options={STATUS} />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <div className="row">
              <div className="col-md-12">
                <a href={Constants.BASE_API + 'admin/account-status/excel?' + Globals.getParams(this.state.form)}>
                  <Button className="btn-blue-create btn-filter" >
                    Exportar Excel
                  </Button>
                </a>
                <a href={Constants.BASE_API + 'admin/account-status/debts/excel?' + Globals.getParams(this.state.form)} target="_blank">
                  <Button className="btn-blue-create btn-filter ml-3">
                    Exportar Deudas
                  </Button>
                </a>
              </div>
              {/* <div className="col-md-6">
                <a href={Constants.BASE_API + 'admin/account-status/pdf?' + Globals.getParams(this.state.form)}>
                  <Button className="btn-blue-create btn-filter">
                    Exportar PDF
                  </Button>
                </a>
              </div> */}
            </div>
          </div>
          <div className="col-md-8">
            <Input
              color="white"
              searchRight
              value={this.state.form.search}
              name="search"
              placeholder="Buscar (Código, ID, Nombre)"
              onChange={(e: any) => {
                // clearTimeout(this.timer);
                // this.timer = setTimeout(() => {
                //   this.load(true);
                // }, 1000);
                this.change(e);
              }}
              onKeyDown={(e: any) => {
                if (e.key === 'Enter') {
                  this.load(true);
                }
              }}
            />
          </div>

          <div className="col-md-12 text-right">
            { checkPermission(VIEW_SEND_EMAIL) &&
              (<Button className="btn-green-create btn-filter left" style={{float:'left'}} onClick={(e:any) => this.sendMails(e)}>
                Enviar Correos
              </Button>
            )}

            {/* <Button className="btn-blue-create btn-filter" onClick={() => this.calculate()}>
              Generar Facturas
            </Button> */}
          </div>
        </div>

        {
          this.state.form != INITIAL_SEARCH && (
            <p className="btn-reset" style={{ marginTop: '15px' }} onClick={this.reset}>Limpiar filtros</p>
          )
        }

        {
          property_selected && (
            <p className="mt-4 mb-0 text-center text-primary">{ property_selected?.full_name } ({property_selected?.codes_urbaser})</p>
          )
        }

        <Table data={this.state.data.length} title="" header={this.state.header}>
          {this.state.data.map((i: any, index: number) => {
            return (
              <tr key={index}>
                <td>{i.user?.number_format}</td>
                <td>{i.user?.username}</td>
                <td>{i.urbaser_code}</td>
                <td className="text-nowrap">{i.type?.name ?? ''}</td>
                <td className="text-nowrap">{i.last_payment ? moment(i.last_payment).format('DD/MM/YYYY') : '-'}</td>
                <td className="text-nowrap">
                  RIF: {i.user?.full_document}<br></br>
                  NOMBRE: { checkPermission(VIEW_CALCULATE) ? <Link to={`/admin/users/clients/edit/${i.user_id}`}> {i.user?.full_name}</Link> : i.user?.full_name} <br></br>
                  NRO: {i.house_number ?? ''}
                </td>
                <td className="text-nowrap text-right">
                  <TooltipCurrency title={i.total_record}>
                    { checkPermission(VIEW_CALCULATE) ?
                      <Link to={`/admin/cash/${i.user_id}`}>
                        {Globals.formatMiles(Currency.Convert(i.total_record, this.state.references.conversion_petro.amount), true, Currency.BOLIVAR)}
                      </Link> :
                      Globals.formatMiles(Currency.Convert(i.total_record, this.state.references.conversion_petro.amount), true, Currency.BOLIVAR)
                    }
                  </TooltipCurrency>
                </td>
                <td className="text-nowrap" onClick={(e: any) => checkPermission(VIEW_CALCULATE) && this.pendingMonths(e, i)}>{i.pending_months || 'Ninguno'}</td>
                <td className="text-nowrap">
                    
                    {i.wasSent ? 'Si' : 'No'}
                    
                </td>
                <td className="icon-actions">
                  { checkPermission(VIEW_SEND_EMAIL) && (<>
                    <Tooltip title="Envio por email">
                      <a className="link-icon" style={{ margin: '5px', marginRight: '10px' }} href="#" onClick={(e: any) => this.toggleCanBeSent(e, i)}>
                        <span className={`table-item-check ${i.canBeSent ? 'active' : ''}`}>
                          <img src={CheckWhite} />
                        </span>
                      </a>
                    </Tooltip>

                    <Tooltip title="Enviar email de forma manual">
                      <a className="link-icon" style={{ margin: '5px', marginRight: '10px' }} href="#" onClick={(e: any) => this.toggleWasSent(e, i)}>
                        <img src={SendIcon} />
                      </a>
                    </Tooltip>

                    <Tooltip title="Enviar notificación">
                      <a className="link-icon" style={{ margin: '5px', marginRight: '10px' }} href="#" onClick={(e: any) => this.toggleNotifySent(e, i)}>
                        <img src={NotifyIcon} />
                      </a>
                    </Tooltip>
                  </>)}

                  { checkPermission(VIEW_CALCULATE) &&
                    <Tooltip title="Sincronizar Estado de Cuenta">
                      <a className="link-icon" style={{ margin: '5px', marginRight: '10px' }} href="#" onClick={(e: any) => this.toggleRefresh(e, i)}>
                        <img src={RefreshIcon} />
                      </a>
                    </Tooltip>
                  }

                  { checkPermission(VIEW_RECORD) && (<>
                    {
                      i.childs != 0 &&
                      <Tooltip title="Ver propiedades">
                        <Link to={{ pathname: `/admin/account-status/${i.id}`, state: null }} className="link-icon" style={{ margin: '5px', marginRight: '10px' }}>
                          <img src={HouseIcon} />
                        </Link>
                      </Tooltip>
                    }
                    <Tooltip title="Ver">
                      <a className="link-icon" href="#" onClick={(e: any) => this.view(e, i)}>
                        <img src={ViewIcon} />
                      </a>
                    </Tooltip>
                    <Tooltip title="Imprimir">
                      <a className="link-icon" href="#" onClick={(e: any) => this.print(e, i)}>
                        <img src={PrintIcon} />
                      </a>
                    </Tooltip>
                  </>)}
                </td>
              </tr>
            )
          })}
        </Table>

        <Pagination
          pages={this.state.last_page}
          active={this.state.page}
          onChange={async (page: number) => {
            await this.setState({
              page: page
            });
            this.load();
          }} />

      </div>
    )
  }
}

export default connect((state: any) => {
  return {
    user: state.user,
  }
})(AccountStatus);