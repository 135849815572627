import React from 'react';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import RegisterPayment from './register-payment';
import { Table, Pagination, Input, Select, Button, Tooltip, DatePicker, Modal, Textarea, PaperClip, TooltipCurrency } from 'components';
import { checkPermission, Permissions, Constants, Socket, SocketEvents, Globals, Print, Currency } from 'utils';
import { CashService } from 'services';
import { EditIcon, ViewIcon, TrashIcon, CheckIcon, CloseIcon, PrintIcon, PaperclipIcon, ViewFileIcon, MoneyIcon } from 'assets/icons';
import moment from 'moment';
import { ImagesAndDocuments } from 'components/paperclip';

interface Props extends RootState, RouteComponentProps {
  dispatch: any
}

interface LocationProps {
  page: number | undefined;
  form: any
}
interface Props extends RootState, RouteComponentProps {
  dispatch: any
}

const INITIAL_STATE = {
  init: '',
  end: '',
  status: '',
  type: '',
  payment_method: '',
  search: ''
}

const INITIAL_STATE_PAYMENT = {
  number: '',
  payment_method_id: '',
  bank_id: '',
  bank_account_id: '',
  date: '',
  file: ''
}

const { PENDING, ACCEPTED, REJECTED, CANCELLED, TO_PAY } = Constants.BILL_STATUS;

const STATUS: any = [
  { value: PENDING, label: 'Por verificar' },
  { value: ACCEPTED, label: 'Verificado' },
  { value: REJECTED, label: 'Rechazado' },
  { value: CANCELLED, label: 'Anulado' },
  { value: TO_PAY, label: 'Por pagar' },
]

class Payments extends React.Component<Props> {  

  timer: any = undefined;
  
  state = {
    data: [],
    page: 1,
    last_page: 1,
    form_payment: INITIAL_STATE_PAYMENT,
    header: [
      'Fecha',
      'Series',
      'Tipo Doc',
      'Periodo',
      'ID - Referencia',
      'Banco',
      'Medio de Pago',
      'Monto',
      'Estatus',
      'Acciones'
    ],
    header_vouchers: [
      'Método de pago',
      'Monto',
      'Banco',
      'Referencia',
      'Fecha de Transferencia',
      'Comprobante',
    ],
    references:{
      payment_methods : [],
      types : [],
      conversion_dolar: {
        amount: 0
      },
      conversion_petro: {
        amount: 0
      }
    },
    form:INITIAL_STATE,
    visible: false,
    client: null,
    types: [],
    methods: [],
    visible_view: false,
    item: null,
    visible_retention: false,
    bank_accounts: [],
    banks: [],
    visible_vouchers: false,
    item_vouchers: null,
  }

  componentDidMount() {
    this.props.dispatch({
      type: 'SET_HEADER',
      payload: {
        title: 'Pagos',
        onBack: null
      }
    });
    this.load();
  }

  load = async (withoutLoading: boolean = false) => {
    const res: any = await CashService.getPayments({
      user_id: this.props.user.id,
      page: this.state.page,
      withoutLoading,
      ...this.state.form,
      since: this.state.form.init && moment(this.state.form.init).format('YYYY-MM-DD'),
      until: this.state.form.end && moment(this.state.form.end).format('YYYY-MM-DD')
    });
    this.setState({
      data: res.bills.data,
      last_page: res.bills.last_page,
      methods: res.methods,
      types: res.types,
      banks: res.banks,
      bank_accounts: res.bank_accounts
    });
  }

  change = (e: any,callback = () => {}) => {
    this.setState({
      page: 1,
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value
      }
    },callback);
  }

  create = () => {
    this.setState({
      visible: true
    });
  }

  onClose = () => {
    this.setState({
      visible: false
    });
  }

  reset = () => {
    this.setState({
      form: INITIAL_STATE,
      page: 1
    },() => this.load(true));
  }

  onCloseView = () => {
    this.setState({
      visible_view: false
    });
  }

  openPaymentRetention = (e: any, item: any) => {
    e.preventDefault();
    this.setState({
      visible_retention: true,
      item
    });
  }

  finish = async () => {
    const item: any = this.state.item;
    let message: string | boolean = false;
    const { TRANSFER, CASH, DEBIT, CREDIT, DEPOSIT } = Constants.PAYMENT_METHODS;

    if (!this.state.form_payment.payment_method_id) {
      message = "Debe ingresar el método de pago";
    }

    if (parseInt(this.state.form_payment.payment_method_id) == TRANSFER) {
      if (!this.state.form_payment.number || !this.state.form_payment.date || !this.state.form_payment.bank_id || !this.state.form_payment.bank_account_id) {
        message = "Debe ingresar todos los datos de pago";
      }
    } 
    else if ([TRANSFER,CREDIT,DEBIT].indexOf(parseInt(this.state.form_payment.payment_method_id)) != -1) {
      if (!this.state.form_payment.number || !this.state.form_payment.bank_id) {
        message = "Debe ingresar todos los datos de pago";
      }
    }
    else if ([DEPOSIT].indexOf(parseInt(this.state.form_payment.payment_method_id)) != -1) {
      if (!this.state.form_payment.number || !this.state.form_payment.date) {
        message = "Debe ingresar todos los datos de pago";
      }
    }

    if (message) {
      Globals.showError(message);
      return false;
    }

    const res: any = await CashService.complete({
      ...this.state.form_payment,
      bill_id: item?.id,
      date: this.state.form_payment.date && moment(this.state.form_payment.date).format('YYYY-MM-DD'),
      hasFile: true
    });
    Globals.showSuccess("Se han guardado los datos correctamente");
    await this.setState({
      visible_retention: false,
      form_payment: INITIAL_STATE_PAYMENT
    },() => this.load(true));
  }

  changePayment = (e: any,callback = () => {}) => {
    this.setState({
      form_payment: {
        ...this.state.form_payment,
        [e.target.name]: e.target.value
      }
    },callback);
  }

  onCloseRetention = () => {
    this.setState({
      visible_retention: false
    });
  }

  viewDetails = (e: any, item: any) => {
    e.preventDefault()
    e.stopPropagation()

    this.setState({
      visible_vouchers: true,
      item_vouchers: item,
    });
  }

  onCloseViewVouchers = () => {
    this.setState({
      visible_vouchers: false,
      item_vouchers: null,
    });
  }

  render() {
    const { visible, visible_view, visible_retention, visible_vouchers } = this.state;
    const item: any = this.state.item;
    const item_vouchers: any = this.state.item_vouchers;
    const { TRANSFER, CASH, DEBIT, CREDIT, DEPOSIT } = Constants.PAYMENT_METHODS;

    return (
      <div id="payments">
        <Modal
            className="high-modal modal-register-payment"
            visible={ visible }
            onClose={ this.onClose } 
            title="Registrar Pago"
        >
          <RegisterPayment load={ () => this.load(true) } user={ this.props.user } />
        </Modal>

        <Modal
            className="high-modal modal-payment-retention"
            visible={ visible_retention }
            onClose={ this.onCloseRetention } 
            title="Subir datos de pago"
        >
          <React.Fragment>
            <div className="row" style={ { marginTop: '20px' } }>
              <div className="col-md-4">
                <Select
                  color="gray"
                  name="payment_method_id"
                  onChange={ this.changePayment }
                  label="Método de Pago"
                  value={ this.state.form_payment.payment_method_id }
                  options={ this.state.methods.map((i: any) => {
                    return {
                      value: i.id,
                      label: i.name
                    }
                  }) } />
              </div>
              {
                [TRANSFER,CREDIT,DEBIT].indexOf(parseInt(this.state.form_payment.payment_method_id)) != -1 && (
                  <React.Fragment>
                    <div className="col-md-4">
                      <Select
                        color="gray"
                        name="bank_id"
                        onChange={ this.changePayment }
                        label="Banco"
                        value={ this.state.form_payment.bank_id }
                        options={ this.state.banks.map((i: any) => {
                          return {
                            value: i.id,
                            label: i.name
                          }
                        }) } />
                    </div>
                    <div className="col-md-4">
                      <Input
                        type="number"
                        color="gray"
                        value={ this.state.form_payment.number }
                        name="number" 
                        label="Nro. de Referencia"
                        helpText={ this.state.form_payment.number?.toString().length !== 8 ? "Escriba los últimos 8 dígitos" : "" }
                        onChange={ this.changePayment } />
                    </div>
                  </React.Fragment>
                )
              }
              {
                [DEPOSIT].indexOf(parseInt(this.state.form_payment.payment_method_id)) != -1 && (
                  <React.Fragment>
                    <div className="col-md-4">
                      <Input
                        type="number"
                        color="gray"
                        value={ this.state.form_payment.number }
                        name="number" 
                        label="Nro. de Referencia"
                        helpText={ this.state.form_payment.number?.toString().length !== 8 ? "Escriba los últimos 8 dígitos" : "" }
                        onChange={ this.changePayment } />
                    </div>
                    <div className="col-md-4">
                      <DatePicker
                        color="gray"
                        label="Fecha de Pago"
                        showYearDropdown={ true }
                        maxDate={ moment().toDate() }
                        onChange={ (value: string) => this.changePayment({
                          target: {
                            value,
                            name: 'date'
                          }
                        }) }
                        value={ this.state.form_payment.date }
                      />
                    </div>
                  </React.Fragment>
                )
              }
              {
                TRANSFER == parseInt(this.state.form_payment.payment_method_id) && (
                  <React.Fragment>
                    <div className="col-md-4">
                      <Select
                        color="gray"
                        name="bank_account_id"
                        onChange={ this.changePayment }
                        label="Cuenta de Banco"
                        value={ this.state.form_payment.bank_account_id }
                        options={ this.state.bank_accounts.map((i: any) => {
                          return {
                            value: i.id,
                            label: i.name
                          }
                        }) } />
                    </div>
                    <div className="col-md-4">
                      <DatePicker
                        color="gray"
                        label="Fecha de Transferencia"
                        showYearDropdown={ true }
                        maxDate={ moment().toDate() }
                        onChange={ (value: string) => this.changePayment({
                          target: {
                            value,
                            name: 'date'
                          }
                        }) }
                        value={ this.state.form_payment.date }
                      />
                    </div>
                    <div className="col-md-4">
                      <PaperClip
                        name="file"
                        formats={ ImagesAndDocuments }
                        value={ this.state.form_payment.file }
                        className={ this.state.form_payment.file && 'active' }
                        successText="Comprobante Cargado"
                        pendingText="Subir Comprobante"
                        onChange={ this.changePayment } />
                    </div>
                  </React.Fragment>
                )
              }
            </div>
            <div className="text-right">
              <Button type="button" className="btn-total" onClick={ () => this.finish() }>
                Guardar
              </Button>
            </div>
          </React.Fragment>
        </Modal>

        <Modal
            className="modal-create-bank-account"
            visible={ visible_view }
            onClose={ this.onCloseView } 
            title="Pago Rechazado"
        >
          <div className="row">
            <div className="col-12">
              <Textarea
                  placeholder="Motivo de rechazo (Opcional)"
                  rows={ 4 }
                  color="white"
                  name="text"
                  disabled={ true }
                  value={ item?.reject_comments } />
            </div> 
          </div>
        </Modal>

        <Modal
          className="modal-view-vouchers"
          visible={ visible_vouchers }
          onClose={ this.onCloseViewVouchers }
          title="Comprobantes de pago"
        >
          <div className="row">
            <div className="col-12">
              <Table data={ item_vouchers?.details_new.length } title="" header={ this.state.header_vouchers } rightAlign={ [8] }>
                { item_vouchers?.details_new.map((i: any, index: number) => {
                  return (
                    <tr key={ index }>
                      <td>{ i.payment_method?.name }</td>
                      <td>
                        <TooltipCurrency title={ i.amount_paid }>{ Globals.formatMiles(Currency.Convert(i.amount_paid, item_vouchers.conversion_petro), true, Currency.BOLIVAR) }</TooltipCurrency>
                      </td>
                      <td>{ i.bank?.name }</td>
                      <td>{ i.number }</td>
                      <td>{ i.date }</td>
                      <td>
                        { i.base64img &&
                          <Tooltip title="Ver Comprobante">
                            <a href={ i.base64img } download={`Comprobante de pago ${i.number}`} className="link-icon">
                              <img src={ ViewFileIcon } alt="Ver Comprobante" />
                            </a>
                          </Tooltip>
                        }
                      </td>
                    </tr>
                  )
                })}
              </Table>
            </div>
          </div>
        </Modal>
        {/* <div className="row justify-content-right">
          <div className="col-md-10">
          </div>
          <div className="col-md-2">
              <Button className="btn-urbaser-green btn-filter mb-4" onClick={ this.create }>
                Realizar Pago
              </Button>
          </div>
        </div>
        <div className="row">
          <div className="col-md-2">
            <DatePicker
              color="white"
              label="Desde"
              value={this.state.form.init}
              onChange={ (value: string) => this.change({ 
                target: { name: 'init', value }                
              },() => this.load(true))}
            />
          </div>
          <div className="col-md-2">
            <DatePicker
              color="white"
              label="Hasta" 
              value={this.state.form.end}
              onChange={ (value: string) => this.change({ 
                target: { name: 'end', value }
              },() => this.load(true))}
            />
          </div>
          <div className="col-md-8">
            <div className="row">
              <div className="col-md-4">
                <Select
                  label="Tipo"
                  color="white"
                  name="type"
                  onChange={(e: any) => this.change(e,() => this.load(true))}
                  placeholder="Tipo"
                  value={ this.state.form.type }
                  options={ this.state.types.map((item: any) => {
                    return {
                      value: item.id,
                      label: item.name
                    }
                  }) } 
                />
              </div>
              <div className="col-md-4">
                <Select
                  label="Medio de Pago"
                  color="white"
                  name="payment_method"
                  onChange={ (e: any) => {
                    this.change(e,() => this.load(true));
                  } }
                  placeholder="Método de pago"
                  value={ this.state.form.payment_method }
                  options={ this.state.methods.map((item: any) => {
                    return {
                      value: item.id,
                      label: item.name
                    }
                  }) } />
              </div>
              <div className="col-md-4">
                <Select
                  label="Estatus"
                  color="white"
                  name="status"
                  onChange={ (e: any) => {                    
                    this.change(e,() => this.load(true));
                  } }
                  placeholder="Estatus"
                  value={ this.state.form.status }
                  options={ STATUS } />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3">

                <a href={ Constants.BASE_API + 'cash/get-excel?' + Globals.getParams({
                  ...this.state.form,
                  user_id: this.props.user.id
                }) }>
                  <Button className="btn-blue-create btn-filter">
                    Exportar Excel
                  </Button>
                </a>

          </div>
          <div className="col-md-3">
            {
              this.state.data.length > 0 && (
                <a href={ Constants.BASE_API + 'cash/get-pdf?' + Globals.getParams({
                  ...this.state.form,
                  user_id: this.props.user.id
                }) }>
                  <Button className="btn-blue-create btn-filter">
                    Exportar PDF
                  </Button>
                </a>

              )
            }
          </div>
          <div className="col-md-6">
            <Input
              color="white"
              searchRight
              value={ this.state.form.search }
              name="search"
              placeholder="Buscar (Código, ID, Nombre, Monto)"
              onChange={ (e: any) => {
                clearTimeout(this.timer);
                this.timer = setTimeout(() => {
                  this.load(true);
                },1000);
                this.change(e); 
              } } 
            />
          </div>
        </div>

         {
            this.state.form != INITIAL_STATE && (
              <p className="btn-reset" style={ { marginTop: '15px' } } onClick={ this.reset }>Limpiar filtros</p>
            )
          }   */}

        <Table data={ this.state.data.length } title="" header={ this.state.header } rightAlign={ [7] }>
          { this.state.data.map((i: any,index: number) => {
            return (
              <tr key={ index }>
                <td className="no-wrap">{ moment(i.created_at).format('DD/MM/YYYY') }</td>
                <td>{ i.serial_code }</td>
                <td className="no-wrap">{ i.bill_type?.name || '-' }</td>
                <td className="capitalize no-wrap">{ i.since ? (moment(i.since).format('MMM YYYY') + (i.until && i.until != i.since ? (' - ' + moment(i.until).format('MMM YYYY')) : '')) : moment(i.created_at).format('MMM YYYY') }</td>
                <td>{ i.details?.number || '-' }</td>
                <td>{ i.details?.bank?.name || '-' }</td>
                <td><a href="#" onClick={(e: any) => this.viewDetails(e, i)}>Ver comprobantes</a></td>
                <td className="no-wrap text-right">
                  <TooltipCurrency title={ i.total }>{ Globals.formatMiles(Currency.Convert(i.total,i.conversion_petro),true,Currency.BOLIVAR) }</TooltipCurrency>
                </td>
                <td className="no-wrap">{ i.status_client_text }</td>
                <td className="icon-actions">

                  <React.Fragment>
                        {
                          i.details?.file && (
                            <Tooltip title="Ver Comprobante">
                              <a target="_blank" className="link-icon" href={ Constants.BASE_STORAGE + i.details?.file }>
                                <img src={ ViewFileIcon } />
                              </a>
                            </Tooltip>
                          )
                        }
                        {
                          i.reject_comments && (
                            <Tooltip title="Ver Comentarios">
                              <a className="link-icon" href="#" onClick={ (e: any) => {
                                e.preventDefault();
                                this.setState({
                                  visible_view: true,
                                  item: i
                                });
                              } }>
                                <img src={ ViewIcon } />
                              </a>
                            </Tooltip>
                          )
                        }

                        {
                          i.status == Constants.BILL_STATUS.ACCEPTED && (
                            <React.Fragment>
                              <Tooltip title="Ver Factura">
                                <a target="_blank" className="link-icon" href={ Constants.BASE_API+ 'admin/cash/print/' + i.crypt + '/' + this.props.user.crypt + '/?type=web'}>
                                  <img src={ ViewIcon } />
                                </a>
                              </Tooltip>
                            </React.Fragment>
                          )
                        }
                    </React.Fragment>
                  {/* {
                    (i.user?.retention == Constants.RETENTION.YES && !i.payment_method_id) ? (
                      <Tooltip title="Subir datos de pago">
                        <a className="link-icon" href="#" onClick={ (e: any) => this.openPaymentRetention(e,i) }>
                          <img src={ MoneyIcon } />
                        </a>
                      </Tooltip>
                    ) : (
                      <React.Fragment>
                        {
                          i.details?.file && (
                            <Tooltip title="Ver Comprobante">
                              <a target="_blank" className="link-icon" href={ Constants.BASE_STORAGE + i.details?.file }>
                                <img src={ ViewFileIcon } />
                              </a>
                            </Tooltip>
                          )
                        }
                        {
                          i.reject_comments && (
                            <Tooltip title="Ver Comentarios">
                              <a className="link-icon" href="#" onClick={ (e: any) => {
                                e.preventDefault();
                                this.setState({
                                  visible_view: true,
                                  item: i
                                });
                              } }>
                                <img src={ ViewIcon } />
                              </a>
                            </Tooltip>
                          )
                        }

                        {
                          i.status == Constants.BILL_STATUS.ACCEPTED && (
                            <React.Fragment>
                              <Tooltip title="Ver Factura">
                                <a target="_blank" className="link-icon" href={ Constants.BASE_API+ 'admin/cash/print/' + i.crypt + '/' + this.props.user.crypt}>
                                  <img src={ ViewIcon } />
                                </a>
                              </Tooltip>
                            </React.Fragment>
                          )
                        }
                      </React.Fragment>
                    )
                  } */}
                </td>
              </tr>
            )
          }) }
        </Table>

        <Pagination 
          pages={ this.state.last_page } 
          active={ this.state.page }
          onChange={ async (page: number) => {
            await this.setState({
              page: page
            });
            this.load();
        } } />
      </div>
    )
  }
}

export default connect((state: RootState) => {
  return{
    user: state.user
  }
})(Payments);